.home{
    .game{
        border-radius: 10px;
        overflow: hidden;
        
        img{
            width: 100%;
        }

        h4{
            padding: 10px 20px;
            color: #fff;
            margin: 0;
        }

        &.game-1 h4{
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), #00C9D0;
        }

        &.game-2 h4{
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), #90E5A8;
        }

        &.game-3 h4{
            background: #F95B28;
        }
    }

    .green-block{
        margin: 0 -20px;
        padding: 40px 20px;
        text-align: center;
        background: $color-dark-green;
        &.light{
            background: $color-extra-light-green;
        }
    }
}