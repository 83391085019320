body{
    padding:0;
    margin:0;
    font-family: 'Roboto', sans-serif;
    color: $color-black;
    font-size:$font-size-normal;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: center;
    background-size: cover;
    
    &.questions{
        background-position-y: top;
    }

    @media #{$screen-lg}{ 
        background-image: url(#{$imagePath}backgrounds/desktop_bg_11.jpg);
    
        &[data-bg="11"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_11.jpg);}
        &[data-bg="12"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_12.jpg);}
        &[data-bg="13"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_13.jpg);}
        &[data-bg="21"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_21.jpg);}
        &[data-bg="22"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_22.jpg);}
        &[data-bg="23"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_23.jpg);}
        &[data-bg="31"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_31.jpg);}
        &[data-bg="32"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_32.jpg);}
        &[data-bg="33"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_33.jpg);}
        &[data-bg="41"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_41.jpg);}
        &[data-bg="42"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_42.jpg);}
        &[data-bg="43"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_43.jpg);}
        &[data-bg="51"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_51.jpg);}
        &[data-bg="52"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_52.jpg);}
        &[data-bg="53"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_53.jpg);}
        &[data-bg="61"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_61.jpg);}
        &[data-bg="62"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_62.jpg);}
        &[data-bg="63"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_63.jpg);}
        &[data-bg="71"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_71.jpg);}
        &[data-bg="72"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_72.jpg);}
        &[data-bg="73"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_73.jpg);}
        &[data-bg="81"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_81.jpg);}
        &[data-bg="82"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_82.jpg);}
        &[data-bg="83"]{background-image: url(#{$imagePath}backgrounds/desktop_bg_83.jpg);}
    }

    @media #{$screen-only-sm-md}{
        &[data-bg="11"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_11.jpg);}
        &[data-bg="12"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_12.jpg);}
        &[data-bg="13"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_13.jpg);}
        &[data-bg="21"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_21.jpg);}
        &[data-bg="22"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_22.jpg);}
        &[data-bg="23"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_23.jpg);}
        &[data-bg="31"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_31.jpg);}
        &[data-bg="32"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_32.jpg);}
        &[data-bg="33"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_33.jpg);}
        &[data-bg="41"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_41.jpg);}
        &[data-bg="42"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_42.jpg);}
        &[data-bg="43"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_43.jpg);}
        &[data-bg="51"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_51.jpg);}
        &[data-bg="52"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_52.jpg);}
        &[data-bg="53"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_53.jpg);}
        &[data-bg="61"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_61.jpg);}
        &[data-bg="62"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_62.jpg);}
        &[data-bg="63"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_63.jpg);}
        &[data-bg="71"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_71.jpg);}
        &[data-bg="72"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_72.jpg);}
        &[data-bg="73"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_73.jpg);}
        &[data-bg="81"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_81.jpg);}
        &[data-bg="82"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_82.jpg);}
        &[data-bg="83"]{background-image: url(#{$imagePath}backgrounds/tablet_bg_83.jpg);}
    }
}

main{
    position: relative;
    min-height: 100vh;
    background: rgba(255, 255, 255, 0.9);

    & > div{
        min-height: calc(100vh - 92px - 55px);
    }

    @media #{$screen-max-xs}{
        background: #fff   
    }
}

.hidden {
    display: none !important;
}

strong, b{font-weight: bold;}

a,
input[type="text"],
textarea,
.btn,
.transition {
    @extend %defTransition;
}

.nowrap{
    white-space: nowrap;
}

.noscroll{
    overflow: hidden;
}

.whitespace-nowrap{
    white-space: nowrap;
}

textarea{
    min-height: 120px!important;
}

.container{
    /* max-width: $container-width; */
    padding-left: 20px !important;
    padding-right: 20px !important;
    overflow: hidden;
}

.row{
    margin-left: -10px !important;
    margin-right: -10px !important;
}

.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1{
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.m-8{margin: 8px !important;}
.mt-8{margin-top: 8px !important;}
.mb-8{margin-bottom: 8px !important;}
.ml-8{margin-left: 8px !important;}
.mr-8{margin-right: 8px !important;}

.m-16{margin: 16px !important;}
.mt-16{margin-top: 16px !important;}
.mb-16{margin-bottom: 16px !important;}
.ml-16{margin-left: 16px !important;}
.mr-16{margin-right: 16px !important;}

.m-20{margin: 20px !important;}
.mt-20{margin-top: 20px !important;}
.mb-20{margin-bottom: 20px !important;}
.ml-20{margin-left: 20px !important;}
.mr-20{margin-right: 20px !important;}

.m-32{margin: 32px !important;}
.mt-32{margin-top: 32px !important;}
.mb-32{margin-bottom: 32px !important;}
.ml-32{margin-left: 32px !important;}
.mr-32{margin-right: 32px !important;}

.m-40{margin: 40px !important;}
.mt-40{margin-top: 40px !important;}
.mb-40{margin-bottom: 40px !important;}
.ml-40{margin-left: 40px !important;}
.mr-40{margin-right: 40px !important;}

.m-48{margin: 48px !important;}
.mt-48{margin-top: 48px !important;}
.mb-48{margin-bottom: 48px !important;}
.ml-48{margin-left: 48px !important;}
.mr-48{margin-right: 48px !important;}

.m-56{margin: 56px !important;}
.mt-56{margin-top: 56px !important;}
.mb-56{margin-bottom: 56px !important;}
.ml-56{margin-left: 56px !important;}
.mr-56{margin-right: 56px !important;}

.m-80{margin: 80px !important;}
.mt-80{margin-top: 80px !important;}
.mb-80{margin-bottom: 80px !important;}
.ml-80{margin-left: 80px !important;}
.mr-80{margin-right: 80px !important;}

.m-96{margin: 96px !important;}
.mt-96{margin-top: 96px !important;}
.mb-96{margin-bottom: 96px !important;}
.ml-96{margin-left: 96px !important;}
.mr-96{margin-right: 96px !important;}

.m-106{margin: 106px !important;}
.mt-106{margin-top: 106px !important;}
.mb-106{margin-bottom: 106px !important;}
.ml-106{margin-left: 106px !important;}
.mr-106{margin-right: 106px !important;}

.p-8{padding: 8px !important;}
.pt-8{padding-top: 8px !important;}
.pb-8{padding-bottom: 8px !important;}
.pl-8{padding-left: 8px !important;}
.pr-8{padding-right: 8px !important;}

.p-16{padding: 16px !important;}
.pt-16{padding-top: 16px !important;}
.pb-16{padding-bottom: 16px !important;}
.pl-16{padding-left: 16px !important;}
.pr-16{padding-right: 16px !important;}

.p-20{padding: 20px !important;}
.pt-20{padding-top: 20px !important;}
.pb-20{padding-bottom: 20px !important;}
.pl-20{padding-left: 20px !important;}
.pr-20{padding-right: 20px !important;}

.p-32{padding: 32px !important;}
.pt-32{padding-top: 32px !important;}
.pb-32{padding-bottom: 32px !important;}
.pl-32{padding-left: 32px !important;}
.pr-32{padding-right: 32px !important;}

.p-40{padding: 40px !important;}
.pt-40{padding-top: 40px !important;}
.pb-40{padding-bottom: 40px !important;}
.pl-40{padding-left: 40px !important;}
.pr-40{padding-right: 40px !important;}

.p-48{padding: 48px !important;}
.pt-48{padding-top: 48px !important;}
.pb-48{padding-bottom: 48px !important;}
.pl-48{padding-left: 48px !important;}
.pr-48{padding-right: 48px !important;}

.p-56{padding: 56px !important;}
.pt-56{padding-top: 56px !important;}
.pb-56{padding-bottom: 56px !important;}
.pl-56{padding-left: 56px !important;}
.pr-56{padding-right: 56px !important;}

.p-80{padding: 80px !important;}
.pt-80{padding-top: 80px !important;}
.pb-80{padding-bottom: 80px !important;}
.pl-80{padding-left: 80px !important;}
.pr-80{padding-right: 80px !important;}

.p-96{padding: 96px !important;}
.pt-96{padding-top: 96px !important;}
.pb-96{padding-bottom: 96px !important;}
.pl-96{padding-left: 96px !important;}
.pr-96{padding-right: 96px !important;}
