.profile{
  .points-box{
    background: #FFFFFF;
    box-shadow: 0 6px 12px rgba(59, 74, 63, 0.15);
    border-radius: 20px;
    margin: 40px 0;
    padding: 20px;
    text-align: center;

    .points-key{
      margin-bottom: 10px;
    }
    .points-val{
      font-weight: 600;
      font-size: 1.4em;
    }
  }

  .profile-settings{
    background: #F6F8F8;
    margin-left: -20px;
    margin-right: -20px;
    box-shadow: 0 6px 12px rgb(59 74 63 / 15%);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .btn-edit, .btn-save {
    background: $color-dark-green;
    line-height: 1;
    width: 28px;
    height: 28px;
    padding: 4px;
    
    svg{
      width: 70%;
      path{
        fill: $color-white;
      }
    }
  }
}

.profile-image{
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}