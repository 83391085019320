.game-cover {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  cursor: pointer;
  height: 100%;

  &:after {
    @extend %defTransition;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: '';
    background: rgba(0, 0, 0, 0.4);
  }

  &:hover::after {
    opacity: 1;
  }

  img {
    width: 100%;
  }

  .cover {
    min-height: 164px;
    background-size: cover;
    background-position: center;
    flex: 1 1 0;
  }

  h4 {
    padding: 10px 20px;
    color: #fff;
    margin: 0;
  }

  &.game-1 h4 {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), #00C9D0;
  }

  &.game-2 h4 {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), #90E5A8;
  }

  &.game-3 h4 {
    background: #F95B28;
  }
}