.games{
    &.game1{
        main{
            width: 100%;
            max-width: 100%;
        }
        .court{
            position: relative;
            width: 100%;

            & > div{
                position: relative;
            }

            .life-stage{
                position: absolute;
                cursor: pointer;
                z-index: 1;
                width: calc((100vw / 1920) * 85);
                height: calc((100vw / 1920) * 85);

                &.lock, &.opening, &.go-opening{
                    background: url(#{$imagePath}/life_stages/lock.png) no-repeat center;
                    background-size: cover;
                }
                &.lock{
                    animation-name: vibration;
                    animation-delay: 0s;
                    animation-duration: 8s;
                    animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
                    animation-iteration-count: infinite;
                    
                    @keyframes vibration {
                        0% {transform: scale(1);}
                        10% {transform: scale(1.2)}
                        20% {transform: scale(1)}
                    }

                    &[data-stage="11"]{animation-delay: 1s;}
                    &[data-stage="12"]{animation-delay: 1.3s;}
                    &[data-stage="13"]{animation-delay: 1.6s;}
                    &[data-stage="21"]{animation-delay: 1.9s;}
                    &[data-stage="22"]{animation-delay: 2.2s;}
                    &[data-stage="23"]{animation-delay: 2.5s;}
                    &[data-stage="31"]{animation-delay: 2.8s;}
                    &[data-stage="32"]{animation-delay: 3.1s;}
                    &[data-stage="33"]{animation-delay: 3.4s;}
                    &[data-stage="41"]{animation-delay: 3.7s;}
                    &[data-stage="42"]{animation-delay: 4s;}
                    &[data-stage="43"]{animation-delay: 4.3s;}
                    &[data-stage="51"]{animation-delay: 4.6s;}
                    &[data-stage="52"]{animation-delay: 4.9s;}
                    &[data-stage="53"]{animation-delay: 5.2s;}
                    &[data-stage="61"]{animation-delay: 5.5s;}
                    &[data-stage="62"]{animation-delay: 5.8s;}
                    &[data-stage="63"]{animation-delay: 6.1s;}
                    &[data-stage="71"]{animation-delay: 6.4s;}
                    &[data-stage="72"]{animation-delay: 6.7s;}
                    &[data-stage="73"]{animation-delay: 7s;}
                    &[data-stage="81"]{animation-delay: 7.3s;}
                    &[data-stage="82"]{animation-delay: 7.6s;}
                    &[data-stage="83"]{animation-delay: 7.9s;}
                    
                }
                
                &.opening{
                    animation-delay: 1.5s;
                    animation-name: go;
                    animation-duration: 1s;
                    animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
                    animation-fill-mode: forwards;
                    animation-iteration-count: 1;


                    @keyframes go {
                        0% {transform: scale(1);}
                        100% {transform: scale(4); opacity: 0;}
                    }
                      
                }

                &[data-stage="11"]{
                    left: 44.6%;
                    bottom: 4.3%;
                }
                &[data-stage="12"]{
                    bottom: 8.1%;
                    left: 33.8%;
                }
                &[data-stage="13"]{
                    bottom: 13.5%;
                    left: 42%;
                }
                &[data-stage="21"]{
                    bottom: 12.8%;
                    left: 60.7%;
                }
                &[data-stage="22"]{
                    bottom: 17.3%;
                    left: 73.1%;
                }
                &[data-stage="23"]{
                    bottom: 20%;
                    left: 60.8%;
                }
                &[data-stage="31"]{
                    bottom: 19.4%;
                    left: 30.9%;
                }
                &[data-stage="32"]{
                    bottom: 25.2%;
                    left: 21.8%;
                }
                &[data-stage="33"]{
                    bottom: 31.1%;
                    left: 31.1%;
                }
                &[data-stage="41"]{
                    bottom: 28.4%;
                    left: 58%;
                }
                &[data-stage="42"]{
                    bottom: 28.4%;
                    left: 78.3%;
                }
                &[data-stage="43"]{
                    bottom: 34.9%;
                    left: 79.6%;
                }
                &[data-stage="51"]{
                    bottom: 41.1%;
                    left: 47.5%;
                }
                &[data-stage="52"]{
                    bottom: 42.8%;
                    left: 29.2%;
                }
                &[data-stage="53"]{
                    bottom: 50.6%;
                    left: 31.8%;
                }
                &[data-stage="61"]{
                    bottom: 51.3%;
                    left: 60.7%;
                }
                &[data-stage="62"]{
                    bottom: 57%;
                    left: 75.3%;
                }
                &[data-stage="63"]{
                    bottom: 57.8%;
                    left: 56.3%;
                }
                &[data-stage="71"]{
                    bottom: 65.5%;
                    left: 49.1%;
                }
                &[data-stage="72"]{
                    bottom: 66.3%;
                    left: 63%;
                }
                &[data-stage="73"]{
                    bottom: 72%;
                    left: 71.3%;
                }
                &[data-stage="81"]{
                    bottom: 73.6%;
                    left: 44.5%;
                }
                &[data-stage="82"]{
                    bottom: 73.5%;
                    left: 29.9%;
                }
                &[data-stage="83"]{
                    bottom: 79.2%;
                    left: 22%;
                }

                @media #{$screen-only-sm-md}{
                    width: calc((100vw / 1300) * 85);
                    height: calc((100vw / 1300) * 85);
                    &[data-stage="11"]{
                        bottom: 4.2%;
                        left: 36.3%;
                    }
                    &[data-stage="12"]{
                        bottom: 8.1%;
                        left: 20.4%;
                    }
                    &[data-stage="13"]{
                        bottom: 13.5%;
                        left: 32.4%;
                    }
                    &[data-stage="21"]{
                        bottom: 12.8%;
                        left: 60%;
                    }
                    &[data-stage="22"]{
                        bottom: 17.3%;
                        left: 78.3%;
                    }
                    &[data-stage="23"]{
                        bottom: 19.9%;
                        left: 60.3%;
                    }
                    &[data-stage="31"]{
                        bottom: 19.4%;
                        left: 16.1%;
                    }
                    &[data-stage="32"]{
                        bottom: 25.2%;
                        left: 2.5%;
                    }
                    &[data-stage="33"]{
                        bottom: 31.1%;
                        left: 16.4%;
                    }
                    &[data-stage="41"]{
                        bottom: 28.3%;
                        left: 55.9%;
                    }
                    &[data-stage="42"]{
                        bottom: 28.3%;
                        left: 86.2%;
                    }
                    &[data-stage="43"]{
                        bottom: 34.9%;
                        left: 88%;
                    }
                    &[data-stage="51"]{
                        bottom: 41.1%;
                        left: 40.4%;
                    }
                    &[data-stage="52"]{
                        bottom: 42.8%;
                        left: 13.6%;
                    }
                    &[data-stage="53"]{
                        bottom: 50.7%;
                        left: 17.4%;
                    }
                    &[data-stage="61"]{
                        bottom: 51.3%;
                        left: 60.2%;
                    }
                    &[data-stage="62"]{
                        bottom: 57%;
                        left: 81.7%;
                    }
                    &[data-stage="63"]{
                        bottom: 57.9%;
                        left: 53.6%;
                    }
                    &[data-stage="71"]{
                        bottom: 65.5%;
                        left: 42.9%;
                    }
                    &[data-stage="72"]{
                        bottom: 66.4%;
                        left: 63.5%;
                    }
                    &[data-stage="73"]{
                        bottom: 72%;
                        left: 75.6%;
                    }
                    &[data-stage="81"]{
                        bottom: 73.6%;
                        left: 36.1%;
                    }
                    &[data-stage="82"]{
                        bottom: 73.6%;
                        left: 14.7%;
                    }
                    &[data-stage="83"]{
                        bottom: 79.3%;
                        left: 3%;
                    }
                }

                @media #{$screen-max-xs}{
                    width: calc((100vw / 576) * 85);
                    height: calc((100vw / 576) * 85);
                    &[data-stage="11"]{
                        left: 33%;
                        bottom: 6%;
                    }
                    &[data-stage="12"]{
                        bottom: 9%;
                        left: 11%;
                    }
                    &[data-stage="13"]{
                        bottom: 13.3%;
                        left: 27.6%;
                    }
                    &[data-stage="21"]{
                        bottom: 14.7%;
                        left: 68%;
                    }
                    &[data-stage="22"]{
                        bottom: 18.2%;
                        left: 52%;
                    }
                    &[data-stage="23"]{
                        bottom: 20%;
                        left: 25%;
                    }
                    &[data-stage="31"]{
                        bottom: 23.5%;
                        left: 41%;
                    }
                    &[data-stage="32"]{
                        bottom: 25%;
                        left: 76%;
                    }
                    &[data-stage="33"]{
                        bottom: 28.7%;
                        left: 57.8%;
                    }
                    &[data-stage="41"]{
                        bottom: 33.9%;
                        left: 19.7%;
                    }
                    &[data-stage="42"]{
                        bottom: 38%;
                        left: 33.5%;
                    }
                    &[data-stage="43"]{
                        bottom: 41.3%;
                        left: 16.5%;
                    }
                    &[data-stage="51"]{
                        bottom: 44.5%;
                        left: 42.5%;
                    }
                    &[data-stage="52"]{
                        bottom: 45.5%;
                        left: 75%;
                    }
                    &[data-stage="53"]{
                        bottom: 50.5%;
                        left: 73%;
                    }
                    &[data-stage="61"]{
                        bottom: 55.1%;
                        left: 13%;
                    }
                    &[data-stage="62"]{
                        bottom: 58.5%;
                        left: 35%;
                    }
                    &[data-stage="63"]{
                        bottom: 63.2%;
                        left: 35%;
                    }
                    &[data-stage="71"]{
                        bottom: 66.8%;
                        left: 11%;
                    }
                    &[data-stage="72"]{
                        bottom: 68%;
                        left: 49%;
                    }
                    &[data-stage="73"]{
                        bottom: 70.4%;
                        left: 77%;
                    }
                    &[data-stage="81"]{
                        bottom: 76.8%;
                        left: 12%;
                    }
                    &[data-stage="82"]{
                        bottom: 81.5%;
                        left: 29%;
                    }
                    &[data-stage="83"]{
                        bottom: 82.3%;
                        left: 62%;
                    }
                }
            }

            .intro{
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: rgb(29 29 27 / 70%);
                z-index: 2;

                .turmix{
                    display: none;
                    position: absolute;
                    right: 20px;
                    bottom: 62px;
                }

                .message{
                    display: none;
                    position: absolute;
                    right: 20px;
                    bottom: 290px;
                    width: calc(100% - 40px);
                    max-width: 500px;
                }
            }
        }

        @media (max-width: 374px){
            .insurance-buttons{
                margin-top: 110px;
            }
        }

        @media (min-height: 800px){
            .insurance-buttons{
                margin-top: 130px;
            }
        }
        #game1-why-insurance{
            position: absolute;
            bottom: 30px;
        }
    }
}