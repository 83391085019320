h1{
    font-size: $font-size-big;
    font-weight: bold;
}

h2{
    font-size: $font-size-big;
    font-weight: bold;
    margin-bottom: 10px;
}

h3{
    font-size: $font-size-extra-medium;
    font-weight: bold;
    margin-bottom: 10px;
}

h4{
    font-size: $font-size-medium;
    font-weight: bold;
}

p{
    font-size: $font-size-normal;
    margin-bottom: 5px;
}

small{
    font-size: $font-size-little;
}

.color-green{
    color: $color-dark-green !important;
}

.color-white{
    color: $color-white !important;
}

.color-red{
    color: $color-red;
}