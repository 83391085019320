.game-2-page{
  margin-top: 52px;

  .cover{
    width: 100%;
    margin-bottom: 20px;
  }

  .t-container{
    padding: 0 20px;
  }

  .point{
    font-size: 1.1em;

    b{
      font-size: 1.3em;
    }
  }

  .start-game-box{
    background: #F6F8F8;
    padding: 20px;
    margin: 20px -20px;

    .btn{
      width: 100%;
      padding: 12px 0;
    }

    .point{
      font-size: 1.3em;
    }
  }

  .share-game-box {
    background: #F6F8F8;
    padding: 20px;
    margin: 20px -20px;

    .g-code{
      font-size: 1.4em;
      font-weight: 600;
    }
  }

  .share-msg{
    background: linear-gradient(266.38deg, rgba(255, 255, 255, 0.5) 4.37%, rgba(255, 255, 255, 0) 98.19%), #FFE081;
    padding: 20px;
    margin: 0 -20px;
  }

  .share-icons {
    width: 100%;
    max-width: 300px;
    display: flex;
    margin: 30px auto;

    a{
      flex: 1 1 0;
      text-align: center;
    }
  }

  .vs-box{
    display: flex;
    align-items: center;

    .vs{
      font-size: 2em;
    }

    div{
      text-align: center;
      flex: 1 1 0;
    }

    .winner {
      position: relative;

      &::after {
        content: "";
        background: url(/public/assets/web/images/winner.png) center no-repeat;
        width: 130px;
        height: 132px;
        position: absolute;
        top: -12px;
        left: 50%;
        margin-left: -65px;
      }
    }
  }

  .winner .profile-image:after{
    content: url(#{$imagePath}winner.png);
    width: 100px;
    height: 100px;
    position: absolute;
    left: -21px;
    top: -21px;
    z-index: 1;
  }
}