footer{
    position: relative;
    z-index: 2;
    margin: 0 -20px;
    padding: 20px 0;
    background: $color-dark-green;
    color: $color-white;
    font-size: $font-size-extra-extra-little;

    ul, li{
        padding: 0;
        margin: 0;
        list-style: none;
    }
    a{
        font-size: $font-size-extra-extra-little;
        color: $color-white;
        text-decoration: underline;
        
        &:hover{
            color: $color-white;
            opacity: 0.7;
        }
    }
}