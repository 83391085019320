.games{
    .container{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .header{
        position: fixed;
        top: 0;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);
        padding: 16px;
        background: $color-dark-green;
        max-width: $container-width;
        padding-left: 20px !important;
        padding-right: 20px !important;
        z-index: 50;
        &.round{
            border-radius: 0 0 20px 20px;
        }
    }

    .footer{
        position: fixed;
        bottom: 0;
        left: 50%;
        width: 100%;
        height: 58px;
        max-width: $container-width;
        transform: translateX(-50%);
        background: $color-white;
        box-shadow: 0px -6px 12px rgba(59, 74, 63, 0.15);
        border-radius: 24px 20px 0px 0px;
        z-index: 100;

        .profile-image{
                position: absolute;
                left: 0;
                top: -10px;
                box-sizing: content-box;
                border: 10px solid $color-white;
                border-radius: 100%;
                padding: 0px;
                height: 50px;
                width: 50px;
                background-position: top center;
                background-repeat: no-repeat;
                background-size: cover;
        }

        .life-stage{
            position: absolute;
            left: 70px;
            top: 12px;
            height: 30px;
            width: 30px;
        }

        .point{
            font-size: $font-size-big;
            font-weight: bold;
            padding-right: 20px;
            width: 40%;
            text-align: right;
        }
    }
}