.score-list,
.game-history-list{
    .item{
        padding: 15px 20px;
        background: linear-gradient(266.42deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), #EBFEEF;
        border-radius: 10px;
        margin: 0 auto 10px;
        max-width: 700px;

        .sort{
            width: 20px;
            font-weight: bold;
        }
        .image{
            width: 40px;
            height: 40px;
            background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 100%;
        }
        .name{
            text-align: left;
            width: 50%;
        }
        .point{
            text-align: right;
            width: 20%;
        }
    }
}

.score-list{
    .item{
        &.item-1{
            background: linear-gradient(266.38deg, rgba(255, 255, 255, 0.5) 4.37%, rgba(255, 255, 255, 0) 98.19%), #FFE081;
        }

        &.item-2{
            background: linear-gradient(266.42deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), #E0E0E0;
        }

        &.item-3{
            background: linear-gradient(266.42deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), #FFC266;
        }
    }
}

.game-history-list{
    .item{
        .data-row{
            flex: 1 1 0;
            margin-left: 20px;
        }

        .status {
            text-align: right;
            color: #1D1D1B;
        }
        .btns{
            text-align: right;
        }

        .point,
        .name{
            font-weight: 600;
            width: 50%;
        }

        &.item-win{
            background: linear-gradient(266.42deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), #c7f6d1;
            .point{
                color: $color-dark-green;
            }
        }

        &.item-lose{
            background: linear-gradient(266.42deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), #f2938b;
            .point{
                color: $color-dark-red;
            }
        }
        &.item-progress{
            background: linear-gradient(266.38deg, rgba(255, 255, 255, 0.5) 4.37%, rgba(255, 255, 255, 0) 98.19%), #FFE081;
        }
    }
}

.point-me {
    position: fixed;
    background: #fff;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 0 3px 0 #9e9e9e;
}