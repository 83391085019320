button{
    //disable ios default settings
    -webkit-appearance: none;
    border-radius: 0;
    @extend %defTransition;
}

.btn{
    font-size: $font-size-normal;
    @extend %defTransition;
    line-height: normal!important;
    border-radius: 20px;
    border: none;
    outline: none;
    &:hover, &:active, &:focus, &:active:focus{
        outline: none !important;
        box-shadow: none !important;
        border: none !important;
    }

    &.w-flex{
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    &.btn-primary{
        background:$color-red;
        padding: 12px 50px;
        color: $color-white;
        svg{

        }

        &:hover, &:active, &:focus, &:active:focus{
            background: $color-dark-red;
        }
        
        &.disabled, &[disabled],&:disabled{
            background: $color-light-red;
        }
    }

    &.btn-secondary{
        background:$color-white;
        padding: 12px 50px;
        color: $color-black;
        border: 1px solid $color-gray;
        svg{

        }

        &:hover, &:active, &:focus, &:active:focus{
            border: 1px solid $color-black !important;
        }
        
        &.disabled, &[disabled],&:disabled{
            border: 1px solid $color-white !important;
            color: $color-gray;
        }
    }
}
.btn-link{
    color: $color-blue;
    text-decoration: underline;
    font-size: $font-size-normal;
    margin: 0;
    padding: 0;
    &:hover{
        opacity: 0.7;
    }

    svg{
        display: inline-block;
        vertical-align: middle;
        height: 11px;
        margin-right: 5px;
        path, g{
            fill: $color-blue;
            stroke: $color-blue;
        }
    }
}