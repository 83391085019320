.fortune-card-box {
  display: flex;
  align-items: center;

  .circle {
    opacity: 0.5;
    border-radius: 100%;
    width: calc(42vw);
    height: calc(42vw);
    max-width: 244px;
    max-height: 244px;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    font-size: 2em;
    color: #fff;
  }
}

.modal-dialog.green .fortune-card-box .circle{
  background: #006633;
}

.modal-dialog.red .fortune-card-box .circle{
  background: $color-red;
}