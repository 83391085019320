#wheel {
  position: relative;
  height: calc(100vh - 136px);
  overflow: hidden;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #90E5A8;
  min-height: calc(576px - 136px);
  
  .btn-spin{
      position: absolute;
      right: 10%;
      bottom: 60%;
      z-index: 20;
  }

  .wheel{
    position: absolute;
    bottom: 0;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }
  .wheel-b {
    width: 100%;
  }
  .wheel-c {
    position: absolute;
    bottom: 4.1%;
    left: -13.8%;
    width: 100%;
    z-index: 11;
    &.spin {
      transition: 5s cubic-bezier(0.14, 0.66, 0.57, 1.04);
    }
  }
}


.modal-pic-100{
  margin: -40px -20px 0 -20px;
  width: calc(100% + 40px);
}