#login{

}

.forgot-password{

}

.character-selector{
    label{
        @extend %defTransition;
        position: relative;
        border-radius: 25px;
        overflow: hidden;
        border: 2px solid transparent;

        &:after{
            @extend %defTransition;
            opacity: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            content: '';
            background: rgba(0, 0, 0, 0.4);
        }
        
        &:hover:after{
            opacity: 1;
        }
    }
    
    input:checked + label{
        border-color: $color-black;
    }
}
