header{
    position: relative;
    margin: 0 -20px;
    padding: 16px;
    background: $color-dark-green;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 10px 10px;
    .logo svg{
        width: 60px;
        height: 60px;
    }

    .slogen{
        padding-left: 10px;
        color: $color-white;
        font-size: 18px;
        line-height: 21px;
        small{
            color: $color-light-green;
            font-size: 14px;
            line-height: 18px;
        }
    }
    
    .profile{
        border: 2px solid $color-white;
        border-radius: 100%;
        padding: 0px;
        height: 40px;
        width: 40px;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        
        &.default{
            background-color: $color-white;
        }
        svg{
            width: 100%;
            path{
                fill: $color-dark-green;
            }
        }
    }
}