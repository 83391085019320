.news-list{
    .item{
        @extend %defTransition;
        display: block;
        border-radius: 10px;
        overflow: hidden;
        filter: drop-shadow(0px 6px 12px rgba(59, 74, 63, 0.15));
        background: $color-white;
        height: 100%;
        &:hover{
            filter: drop-shadow(0px 6px 12px rgba(59, 74, 63, 0.5));
        }

        img{
            min-height: 200px;
        }
        .content{
            text-align: left;
            padding: 20px;
            color: $color-black;

            small{
                color: $color-gray
            }
        }
    }
}