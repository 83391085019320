.form-group{
    position: relative;
  margin-bottom: 20px;  
}
input[type="text"], 
input[type="password"], 
input[type="number"], 
input[type="email"],
textarea{
    position: relative;
    padding: 7px 12px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid $color-gray;
    box-sizing: border-box;
    border-radius: 10px !important;

    &:hover, &:focus{
        border-color: $color-blue;
        outline: none !important;
        box-shadow: none !important;
    }
} 

input[type="password"]{
    padding-right: 35px;
}

input + svg{
    position: absolute;
    top: 13px;
    right: 10px;
    width: 18px;
    height: 12px;
    z-index: 10;
} 

.new-password{
    ul, li{
        margin: 0;
        padding: 0;
        list-style: none;
    }

    ul li{
        position: relative;
        left: 34px;
        margin-bottom: 5px;
        color: $color-gray;
        font-size: $font-size-little;
        
        &.checked{
            color: $color-dark-green;
            &:before{
                position: absolute;
                top: 2px;
                left: -26px;
                content: "";
                width: 7px;
                height: 12px;
                border: solid $color-dark-green;
                border-width: 0 3px 3px 0;
                transform: rotate(45deg);
                @extend %defTransition;
            }
        }
    }
}

.has-error{
    input{
        border-color: $color-red;
    }
    
    .invalid-feedback{
        color: $color-red;
        font-size: $font-size-normal;
    }
}

.checkbox{
    label{
        position: relative;
        margin-left: 34px;
        &:before{
            position: absolute;
            content: '';
            top: -2px;
            left: -34px;
            border: 1px solid $color-gray;
            background: #fff;
            width: 24px;
            height: 24px;
            @extend %defTransition;
        }
    }

    input{
        display: none;
    }

    input:checked + label:before{
        background: $color-dark-green;
        @extend %defTransition;
    }
    
    input:checked + label:after{
        position: absolute;
        top: 2px;
        left: -26px;
        content: "";
        width: 7px;
        height: 12px;
        border: solid $color-white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        @extend %defTransition;
    }
}


.radio{
    label{
        position: relative;
        margin-left: 34px;
        &:before{
            position: absolute;
            content: '';
            top: -2px;
            left: -34px;
            border: 1px solid $color-black;
            border-radius: 100%;
            background: #fff;
            width: 24px;
            height: 24px;
            @extend %defTransition;
        }
    }

    input{
        display: none;
    }

    input:checked + label:after{
        position: absolute;
        top: 4px;
        left: -28px;
        content: "";
        width: 12px;
        height: 12px;
        background-color: $color-dark-green;
        border-radius: 100%;
        @extend %defTransition;
    }
}

