body.games.questions main{
  max-width: 576px !important;
  background: #fff;
}

.question{
  background: linear-gradient(266.38deg, rgba(255, 255, 255, 0.5) 4.37%, rgba(255, 255, 255, 0) 98.19%), #FFE081;
  padding: 20px;
}

.question-text{
  padding: 20px;
}

.game-status-bar-container {
  position: fixed;
  top: 50px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  z-index: 50;
  max-width: $container-width;

  .game-status-bar-box {
    background: $color-dark-green;
    color: #fff;
    padding: 0px 20px 20px;
    border-radius: 0 0 10px 10px;
    margin: 0 auto;
    max-width: $container-width;


    .status-text {
      font-weight: 600;
      margin-bottom: 10px;
    }

    .game-status-bar {
      background: $color-gray;
      border-radius: 10px;
      height: 8px;
      position: relative;

      span {
        display: inline-block;
        background: #00C9D0;
        height: 8px;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 10px 0 0 10px;
        
      }
    }
  }
}

.answers{
  .help-text{
    color: $color-gray;
    padding: 10px 20px 20px 20px;
  }

  .answer{
    position: inherit;
    
    input{
      display: none;
    }
    label {
      background: #FFF;
      border: 2px solid #FFF;
      box-sizing: border-box;
      box-shadow: 0 6px 12px rgba(59, 74, 63, 0.15);
      border-radius: 10px;
      margin: 20px 0;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      width: 100%;

      &:hover {
        border: 2px solid $color-gray;
      }

      img{
        max-height: 40px;
        margin-right: 15px;
      }

      @media (max-width: 374px){
          padding: 5px;
          font-size: 13px;

          img{
            width: 20px;
          }
      }
    }

    input:checked + label{
      border: 2px solid $color-dark-green;

      &:hover {
        border: 2px solid $color-light-green;
      }
    }

    &.ok{
      label{
        background: $color-light-green !important;
        border: 2px solid $color-dark-green !important;
      }
      &:after{
        content: "";
        background: url(#{$imagePath}/svg/ok2.svg) $color-dark-green no-repeat center;
        width: 20px;
        height: 20px;
        position: absolute;
        border-radius: 50%;
        z-index: 3;
        top: -6px;
        right: 12px;
      }
    }

    &.good{
      label{
        background: $color-light-green !important;
        border: 2px solid $color-dark-green !important;
      }
      &:after{
        content: "";
        background: url(#{$imagePath}/svg/no2.svg) $color-dark-red no-repeat center;
        width: 20px;
        height: 20px;
        position: absolute;
        border-radius: 50%;
        z-index: 3;
        top: -6px;
        right: 12px;
      }
    }

    &.wrong{
      label{
        background: $color-light-red !important;
        border: 2px solid $color-dark-red !important;
      }
      &:after{
        content: "";
        background: url(#{$imagePath}/svg/no2.svg) $color-dark-red no-repeat center;
        width: 20px;
        height: 20px;
        position: absolute;
        border-radius: 50%;
        z-index: 3;
        top: -6px;
        right: 12px;
      }
    }

  }

  &.type-2{
    .answer {
      position: relative;
      label {
        margin: 5px 0;
        width: 100%;
        color: $color-white;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), #00C9D0;
        border: 2px solid transparent;
        min-height: 70px;
        display: flex;
        align-items: center;
        grid-auto-flow: column;
      }

      input:checked + label{
        border: 2px solid $color-dark-green;
        
        &:hover {
          border: 2px solid $color-dark-green;
        }
      }

      &.ok{
        label{
          background: rgba(0, 102, 51, 0.7) !important;
          border: 2px solid #006633 !important;
        }
        &:after{
          content: "";
          background: url(#{$imagePath}/svg/ok2.svg) $color-dark-green no-repeat center;
          width: 20px;
          height: 20px;
          position: absolute;
          border-radius: 50%;
          z-index: 3;
          top: 10px;
          right: 2px;
        }
      }
  
      &.good{
        label{
          background: rgba(0, 102, 51, 0.7) !important;
          border: 2px solid rgba(0, 102, 51, 0.7) !important;
        }
        &:after{
          content: "";
          background: url(#{$imagePath}/svg/no2.svg) $color-dark-red no-repeat center;
          width: 20px;
          height: 20px;
          position: absolute;
          border-radius: 50%;
          z-index: 3;
          top: 10px;
          right: 2px;
        }
      }
  
      &.wrong{
        label{
          background: #F7BDB8 !important;
          border: 2px solid #E42313 !important;
        }
        &:after{
          content: "";
          background: url(#{$imagePath}/svg/no2.svg) $color-dark-red no-repeat center;
          width: 20px;
          height: 20px;
          position: absolute;
          border-radius: 50%;
          z-index: 3;
          top: 10px;
          right: 2px;
        }
      }
    }
  }

  .row{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .s-img{
    vertical-align: bottom;
    position: absolute;
    right: 6px;
    bottom: 44px;
  }

  .attitude-image{
    margin-bottom: -90px;
    @media (max-width: 500px) {
      margin-bottom: -20px;
    }
  }
}

