// how to use breakpoints
//@media #{$screen-max-xs}{
// }

// BREAKPOINTS - Bootstrap 4

// SCREEN SIZES

$screen-breakpoints : (
    xxs: 0,
    xs: 340px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

// MEDIA QUERIES
$screen-xs: "only screen and (min-width:" + map-get($screen-breakpoints, "xs")  +")";
$screen-sm: "only screen and (min-width:" + map-get($screen-breakpoints, "sm")  +")";
$screen-md: "only screen and (min-width:" + map-get($screen-breakpoints, 'md')  +")";
$screen-lg: "only screen and (min-width:" + map-get($screen-breakpoints, "lg")  +")";
$screen-xl: "only screen and (min-width:" + map-get($screen-breakpoints, "xl")  +")";

$screen-max-xxs: "only screen and (max-width: "+ (map-get($screen-breakpoints, "xs") - 1) +")";
$screen-max-xs: "only screen and (max-width: "+ (map-get($screen-breakpoints, "sm") - 1) +")";
$screen-max-sm: "only screen and (max-width: "+ (map-get($screen-breakpoints, "md") - 1) +")";
$screen-max-md: "only screen and (max-width: "+ (map-get($screen-breakpoints, "lg") - 1) +")";
$screen-max-lg: "only screen and (max-width: "+ (map-get($screen-breakpoints, "xl") - 1) +")";

$screen-only-sm: "only screen and (min-width:" + map-get($screen-breakpoints, "sm") +") and (max-width: " + (map-get($screen-breakpoints, "md") - 1) +")";
$screen-only-md: "only screen and (min-width:" + map-get($screen-breakpoints, "md") +") and (max-width: " + (map-get($screen-breakpoints, "lg") - 1) +")";
$screen-only-lg: "only screen and (min-width:" + map-get($screen-breakpoints, "lg") +") and (max-width: " + (map-get($screen-breakpoints, "xl") - 1) +")";
$screen-only-sm-md: "only screen and (min-width:" + map-get($screen-breakpoints, "sm") +") and (max-width: " + (map-get($screen-breakpoints, "lg") - 1) +")";
$screen-only-md-lg: "only screen and (min-width:" + map-get($screen-breakpoints, "md") +") and (max-width: " + (map-get($screen-breakpoints, "xl") - 1) +")";


// paths
// how to use:
// background: url(#{$imagePath}/wrapper.png) no-repeat center;

$imagePath: '/assets/web/images/';

// fonts
$font-size-extra-extra-little: 10px;
$font-size-extra-little: 11px;
$font-size-little: 12px;
$font-size-normal: 14px;
$font-size-medium: 16px;
$font-size-extra-medium: 20px;
$font-size-big: 24px;

$letter-spacing:0.02em;

$container-width: 575px;

// colors
$color-white: #ffffff;
$color-gray: #C0C0C0;
$color-black: #1D1D1B;
$color-light-red: #F29189;
$color-red: #E42313;
$color-dark-red: #B61C0F;
$color-extra-light-green: #E6F9EA;
$color-light-green: #90E5A8;
$color-dark-green: #006633;
$color-blue: #009EDD;