.modal-dialog{
    margin-top: 20vh;

    .modal-content{
        border-radius: 20px;
        padding: 40px 20px;
    }

    .close-btn{
        position: absolute;
        top: 5px;
        right: 0;
    }

    .font-2{
        font-size: 2em;
    }

    &.yellow .modal-content{
        background: linear-gradient(266.38deg, rgba(255, 255, 255, 0.5) 4.37%, rgba(255, 255, 255, 0) 98.19%), #FFE081;
    }

    &.green .modal-content{
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), #90E5A8;
        .close-btn svg path:first-child{
            fill: #006633;
        }
    }

    &.red .modal-content{
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), #F7BDB8;
    }
}

.message{
    margin: 20px 0;
    border-radius: 20px;
    padding: 20px 35px 20px 20px;
    position: relative;
    background: linear-gradient(266.38deg, rgba(255, 255, 255, 0.5) 4.37%, rgba(255, 255, 255, 0) 98.19%), #FFE081;
    
    &.do-you-know{
        position: absolute;
        bottom: -10px;
        right: 100%;
        width: 65vw;
        max-width: 390px;
        z-index: 10;
    }

    .close-btn{
        position: absolute;
        top: 5px;
        right: 0;
    }

    &.arrow-up{
        &::after{
            content: "";
            width: 0;
            height: 0;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid #ffe8a2;
            position: absolute;
            top: -12px;
            left: calc(50% - 12px);
        }

        &.arrow-25::after{
            left: calc(25% - 12px);
        }

        &.arrow-75::after{
            left: calc(75% - 12px);
        }
    }

    &.arrow-down{
        &::after{
            content: "";
            width: 0;
            height: 0;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-top: 12px solid #ffe8a2;
            position: absolute;
            bottom: -12px;
            left: calc(50% - 12px);
        }

        &.arrow-25::after{
            left: calc(25% - 12px);
        }

        &.arrow-75::after{
            left: calc(75% - 12px);
        }
    }
    &.arrow-right{
        &::after{
            content: "";
            width: 0;
            height: 0;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-left: 12px solid #ffe8a2;
            position: absolute;
            right: -12px;
            top: calc(50% - 12px);
        }

        &.arrow-25::after{
            top: calc(25% - 12px);
        }

        &.arrow-75::after{
            top: calc(75% - 12px);
        }
    }
}

.modal-down{
    display: none;
    opacity: 0;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background:rgba(0, 0, 0, 0.5);
    z-index: 10;

    .body{
        position: fixed;
        bottom: -500px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: $container-width;
        padding: 20px;
        border-radius: 20px 20px 0 0;
        background: $color-white;

        .title{
            color: $color-gray;
            margin-bottom: 10px;
        }

        &.show{
            bottom: 0;
        }
    }

    &.show{
        display: block;
        opacity: 1;
    }
}